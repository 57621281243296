<template>
  <client-only>
    <div class="footer" v-if="resolution.width > resolution.notebook">

      <component :is="PcOurFooter" v-if="mode === 'our'" />
      <component :is="PcExpansionFooter" v-else-if="mode === 'expansion'" />
      <component :is="PcContainerFooter" v-else-if="mode === 'container'" />
      <component :is="PcDefaultFooter" v-else />

    </div>
    <div class="footer mobile" v-else>

      <component :is="MobileOurFooter" v-if="mode === 'our'" />
      <component :is="MobileExpansionFooter" v-else-if="mode === 'expansion'" />
      <component :is="MobileContainerFooter" v-else-if="mode === 'container'" />
      <component :is="MobileDefaultFooter" v-else />

    </div>
  </client-only>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'
// import PcDefaultFooter from '~/component/layout/default-footer-pc.vue'
// import MobileDefaultFooter from '~/component/layout/default-footer-mobile.vue'
// import PcExpansionFooter from '~/component/layout/expansion-footer-pc.vue'
// import MobileExpansionFooter from '~/component/layout/expansion-footer-mobile.vue'
// import PcContainerFooter from '~/component/layout/container-footer-pc.vue'
// import MobileContainerFooter from '~/component/layout/container-footer-mobile.vue'
// import PcOurFooter from '~/component/layout/our-footer-pc.vue'
// import MobileOurFooter from '~/component/layout/our-footer-mobile.vue'

export default {
  setup () {

    // 글로벌 스토어
    const { layout } = storeToRefs(useGlobalStore())
    const { resolution } = storeToRefs(useGlobalStore())

    // 메모리 비우기
    function restore () {
      PcDefaultFooter.value = null
      MobileDefaultFooter.value = null
      PcExpansionFooter.value = null
      MobileExpansionFooter.value = null
      PcContainerFooter.value = null
      MobileContainerFooter.value = null
      PcOurFooter.value = null
      MobileOurFooter.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const PcDefaultFooter: any = shallowRef(null)
    const MobileDefaultFooter: any = shallowRef(null)
    const PcExpansionFooter: any = shallowRef(null)
    const MobileExpansionFooter: any = shallowRef(null)
    const PcContainerFooter: any = shallowRef(null)
    const MobileContainerFooter: any = shallowRef(null)
    const PcOurFooter: any = shallowRef(null)
    const MobileOurFooter: any = shallowRef(null)
    const loadComponent = async () => {
      const PcDefaultFooter_load = await import('~/component/layout/default-footer-pc.vue')
      const MobileDefaultFooter_load = await import('~/component/layout/default-footer-mobile.vue')
      const PcExpansionFooter_load = await import('~/component/layout/expansion-footer-pc.vue')
      const MobileExpansionFooter_load = await import('~/component/layout/expansion-footer-mobile.vue')
      const PcContainerFooter_load = await import('~/component/layout/container-footer-pc.vue')
      const MobileContainerFooter_load = await import('~/component/layout/container-footer-mobile.vue')
      const PcOurFooter_load = await import('~/component/layout/our-footer-pc.vue')
      const MobileOurFooter_load = await import('~/component/layout/our-footer-mobile.vue')
      PcDefaultFooter.value = PcDefaultFooter_load.default || PcDefaultFooter_load
      MobileDefaultFooter.value = MobileDefaultFooter_load.default || MobileDefaultFooter_load
      PcExpansionFooter.value = PcExpansionFooter_load.default || PcExpansionFooter_load
      MobileExpansionFooter.value = MobileExpansionFooter_load.default || MobileExpansionFooter_load
      PcContainerFooter.value = PcContainerFooter_load.default || PcContainerFooter_load
      MobileContainerFooter.value = MobileContainerFooter_load.default || MobileContainerFooter_load
      PcOurFooter.value = PcOurFooter_load.default || PcOurFooter_load
      MobileOurFooter.value = MobileOurFooter_load.default || MobileOurFooter_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      layout,
      resolution,

      // 컴포넌트
      PcDefaultFooter,
      MobileDefaultFooter,
      PcExpansionFooter,
      MobileExpansionFooter,
      PcContainerFooter,
      MobileContainerFooter,
      PcOurFooter,
      MobileOurFooter
    }
  },
  props: {
    mode: {
      type: String,
      default () {
        return ''
      }
    }
  }
}
</script>